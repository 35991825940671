<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-row>
      <v-col cols="12">
        <base-material-card
          icon="mdi-human-greeting"
          color="yellow darken-1"
        >
          <template #after-heading>
            <div class="display-2 font-weight-light">
              {{ $auth.user().name }}
              <span class="body-1">— {{ $auth.user().customer.mv_name }}</span>
            </div>
          </template>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'Dashboard',

    data () {
      return {}
    },

    computed: {
      totalSales () {
        return this.sales.reduce((acc, val) => acc + val.salesInM, 0)
      },
    },

    methods: {
      complete (index) {
        this.list[index] = !this.list[index]
      },
    },
  }
</script>
